import React, { useContext } from "react"
import { stack as Menu } from "react-burger-menu"
import { Link } from "gatsby"

import { BurgerContext } from "../context.js/BurgerContext"

// November 4, 2021
// see react-burger-menu docs for information on props for managing the state of the menu (i.e. close on click)
// onClick function was added separtely to each link bc menu layout is buggy when using a common React component for some reason

const HamburgerMenu = () => {
  const context = useContext(BurgerContext)

  return (
    <Menu
      right
      isOpen={context.isBurgerOpen}
      onStateChange={menuState => context.stateChangeHandler(menuState)}
    >
      <Link
        to="/"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Home
      </Link>
      {/* <Link
        to="/news"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        News
      </Link> */}
      <Link
        to="/divisions"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Divisions
      </Link>
      <Link
        to="/calendar"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Calendar
      </Link>
      <Link
        to="/registration"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Registration
      </Link>
      <Link
        to="/downloads"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Downloads
      </Link>
      {/* <Link
        to="/photos"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Photos
      </Link> */}
      {/* <Link
        to="/faq"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
      >
        FAQ
      </Link> */}
      {/* <Link
        to="/executive"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
      >
        Executive
      </Link> */}
      <Link
        to="/contact"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Contact
      </Link>
      <Link
        to="/admin/resources"
        className="hamburger__menu-item"
        activeClassName="hamburger--activeLink"
        onClick={() => context.toggleMenu()}
      >
        Resources
      </Link>

      <a
        href="https://be.contentful.com/login"
        className="hamburger__menu-item adminLogin"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => context.toggleMenu()}
      >
        Login
      </a>
    </Menu>
  )
}

export default HamburgerMenu
